/* eslint-disable no-console */

import {register} from 'register-service-worker'
import {alertController} from "@ionic/vue";

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        async ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered() {
            console.log('Service worker has been registered.')
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound(event) {
            console.log('New content is available; please refresh.', event)

            // window.location.reload(true);
            async function presentAlertConfirm() {
                const alert = await alertController
                    .create({
                        header: 'Uw wachtwoord is mogelijk gereset!',
                        message: 'U bent mogelijk automatisch uitgelogd omdat uw wachtwoord is gereset. Log in dat geval opnieuw in met het tijdelijke wachtwoord en kies daarna een nieuw wachtwoord. Het tijdelijke wachtwoord is: 12345Login',
                        buttons: [
                            {
                                text: 'OK!',
                                cssClass: "alert-button-confirm",
                                id: 'confirm-button',
                                handler: async () => {
                                    window.location.reload(true);
                                    console.log('Confirm Okay');
                                },
                            },
                        ],
                        backdropDismiss: false,
                    });
                return alert.present();
            }

            presentAlertConfirm();
        },
        updated(event) {
            console.log('New content is available; please refresh.', event)
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        },
    })
} else {
    register(`${process.env.BASE_URL}service-worker.js`, {
        async ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered() {
            console.log('Service worker has been registered.')
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound(event) {
            console.log('New content is available; please refresh.', event)

            // window.location.reload(true);
            async function presentAlertConfirm() {
                const alert = await alertController
                    .create({
                        header: 'Uw wachtwoord is mogelijk gereset!',
                        message: 'U bent mogelijk automatisch uitgelogd omdat uw wachtwoord is gereset. Log in dat geval opnieuw in met het tijdelijke wachtwoord en kies daarna een nieuw wachtwoord. Het tijdelijke wachtwoord is: 12345Login',
                        buttons: [
                            {
                                text: 'OK!',
                                cssClass: "alert-button-confirm",
                                id: 'confirm-button',
                                handler: async () => {
                                    window.location.reload(true);
                                    console.log('Confirm Okay');
                                },
                            },
                        ],
                        backdropDismiss: false,
                    });
                return alert.present();
            }

            presentAlertConfirm();
        },
        updated(event) {
            console.log('New content is available; please refresh.', event)
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        },
    })
}
